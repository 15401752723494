﻿@charset "UTF-8";
$font-path: "../public/fonts/";

@font-face {
    font-family: 'fontello';
    src: url('#{$font-path}fontello.eot?17310190');
    src: url('#{$font-path}fontello.eot?17310190#iefix') format('embedded-opentype'), 
        url('#{$font-path}fontello.woff2?17310190') format('woff2'), 
        url('#{$font-path}fontello.woff?17310190') format('woff'), 
        url('#{$font-path}fontello.ttf?17310190') format('truetype'), 
        url('#{$font-path}fontello.svg?17310190#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: 0.2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-plus:before {
    content: '\2b';
}
/* '+' */
.icon-minus:before {
    content: '\2d';
}
/* '-' */
.icon-soundcloud:before {
    content: '\73';
}
/* 's' */
.icon-pause:before {
    content: '\2389';
}
/* '⎉' */
.icon-stop:before {
    content: '\25aa';
}
/* '▪' */
.icon-play:before {
    content: '\25b6';
}
/* '▶' */
.icon-star:before {
    content: '\2605';
}
/* '★' */
.icon-star-empty:before {
    content: '\2606';
}
/* '☆' */
.icon-heart-empty:before {
    content: '\2661';
}
/* '♡' */
.icon-heart:before {
    content: '\2665';
}
/* '♥' */
.icon-mail:before {
    content: '\2709';
}
/* '✉' */
.icon-edit:before {
    content: '\270d';
}
/* '✍' */
.icon-pencil:before {
    content: '\270e';
}
/* '✎' */
.icon-ok:before {
    content: '\2713';
}
/* '✓' */
.icon-cancel:before {
    content: '\2715';
}
/* '✕' */
.icon-pin-outline:before {
    content: '\e0ba';
}
/* '' */
.icon-pin:before {
    content: '\e0bb';
}
/* '' */
.icon-info-circled:before {
    content: '\e705';
}
/* '' */
.icon-tags:before {
    content: '\e70d';
}
/* '' */
.icon-trash-empty:before {
    content: '\e729';
}
/* '' */
.icon-share:before {
    content: '\e73c';
}
/* '' */
.icon-basket:before {
    content: '\e73d';
}
/* '' */
.icon-down-open:before {
    content: '\e75c';
}
/* '' */
.icon-left-open:before {
    content: '\e75d';
}
/* '' */
.icon-right-open:before {
    content: '\e75e';
}
/* '' */
.icon-up-open:before {
    content: '\e75f';
}
/* '' */
.icon-link-ext:before {
    content: '\f08e';
}
/* '' */
.icon-bookmark-empty:before {
    content: '\f097';
}
/* '' */
.icon-twitter:before {
    content: '\f099';
}
/* '' */
.icon-facebook:before {
    content: '\f09a';
}
/* '' */
.icon-menu:before {
    content: '\f0c9';
}
/* '' */
.icon-mail-alt:before {
    content: '\f0e0';
}
/* '' */
.icon-linkedin:before {
    content: '\f0e1';
}
/* '' */
.icon-github:before {
    content: '\f113';
}
/* '' */
.icon-youtube:before {
    content: '\f167';
}
/* '' */
.icon-youtube-play:before {
    content: '\f16a';
}
/* '' */
.icon-instagram:before {
    content: '\f16d';
}
/* '' */
.icon-flickr:before {
    content: '\f16e';
}
/* '' */
.icon-apple:before {
    content: '\f179';
}
/* '' */
.icon-spotify:before {
    content: '\f1bc';
}
/* '' */
.icon-git:before {
    content: '\f1d3';
}
/* '' */
.icon-paper-plane:before {
    content: '\f1d8';
}
/* '' */
.icon-lastfm:before {
    content: '\f202';
}
/* '' */
.icon-pinterest:before {
    content: '\f231';
}
/* '' */
.icon-amazon:before {
    content: '\f270';
}
/* '' */
.icon-shopping-basket:before {
    content: '\f291';
}
/* '' */
.icon-bandcamp:before {
    content: '\f2d5';
}
/* '' */
.icon-linkedin-squared:before {
    content: '\f30c';
}
/* '' */
.icon-search:before {
    content: '🔍';
}
/* '\1f50d' */
.icon-bookmark:before {
    content: '🔖';
}
/* '\1f516' */
.icon-link:before {
    content: '🔗';
}
/* '\1f517' */
@font-face {
    font-family: 'righteousregular';
    src: url('#{$font-path}righteous-regular-webfont.eot');
    src: url('#{$font-path}righteous-regular-webfont.eot?#iefix') format('embedded-opentype'), 
        url('#{$font-path}righteous-regular-webfont.woff2') 
    format('woff2'), url('#{$font-path}righteous-regular-webfont.woff') format('woff'), 
        url('#{$font-path}righteous-regular-webfont.ttf') format('truetype'), 
        url('#{$font-path}righteous-regular-webfont.svg#righteousregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'montserratbold';
    src: url('#{$font-path}montserrat-bold-webfont.eot');
    src: url('#{$font-path}montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'), 
        url('#{$font-path}montserrat-bold-webfont.woff2') format('woff2'), 
        url('#{$font-path}montserrat-bold-webfont.woff') format('woff'), 
        url('#{$font-path}montserrat-bold-webfont.ttf') format('truetype'), 
        url('#{$font-path}montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'montserratregular';
    src: url('#{$font-path}montserrat-regular-webfont.eot');
    src: url('#{$font-path}montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'), 
        url('#{$font-path}montserrat-regular-webfont.woff2') format('woff2'), 
        url('#{$font-path}montserrat-regular-webfont.woff') format('woff'), 
        url('#{$font-path}montserrat-regular-webfont.ttf') format('truetype'), 
        url('#{$font-path}montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/*# sourceMappingURL=fonts.css.map */