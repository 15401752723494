/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */
@import "../node_modules/bootstrap/scss/_functions.scss";
// Overrides
$white: #fff;
$gray-200: #eee;
$gray-300: #ddd;
$gray-400: #ccc;
$gray-500: #adb5bd;
$gray-600: #777;
$gray-700: #495057;
$gray-800: #333;
$gray-900: #222;
$black: #000;
$primary: $white;
$secondary: #F90046;

$font-family-base: 'montserratregular','montserratbold', sans-serif;
$font-family-brand: 'righteousregular', Arial;

$body-bg: $gray-900;
$body-color: $white;

$link-decoration: none;
$link-hover-decoration: underline;
$btn-border-radius: 0;

@import "../node_modules/bootstrap/scss/_variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "fonts.scss";
@import "sidebar.scss";

.brand-header {
    font-family: $font-family-brand;
    font-size: 4rem;
    letter-spacing: 1rem;
    background-image: url(../public/icons/icons-vector-v2.svg);
    background-size:contain;
    background-repeat:no-repeat;
    background-position-x:center;
    background-position-y:center;


    &:hover {
        text-decoration: none;
        color:$white;
    }
}

#scroll-top {
    position: fixed;
    right: 2rem;
    bottom: 1rem;
    padding: 0.5rem;
}

#menu-toggle {
    position: fixed;
    left: 2rem;
    top: 1rem;
    padding: 0.5rem;
    z-index:1000;
}

@media(max-width:768px) {
    #scroll-top
    {
        right: 1rem;
    }

    #menu-toggle {
        left: 1rem;
    }
}

@media(max-width:476px) {
    .brand-header {
        font-size:2rem;
    }
}

#footer-social-links {
    li {
        a:hover {
            color: $secondary;
        }
    }
}

.bandcamp-follow {
    height: 33px;
    width: 100%;
}

.dashed-border {
    border-left: 2px dashed #fff;
    border-top: 2px dashed #fff;
    display: inline-block;
    margin-top: 0.75rem;
    padding-left: 1rem;
    padding-top: 0.75rem;
}

.caption {
    text-align: center;
    margin-bottom:0.5rem;

    a {
        font-size: 1.2rem;
        background-color: $secondary;
        border-color: $secondary;
        margin-top: 0.5rem;

        &:hover {
            background-color: $black;
            border-color: $secondary;
            color: $white;
        }
    }
}

.btn-secondary {
    color: $white;

    &:hover {
        background-color: $black;
        color: $white;
    }
}

.video {
    position: relative;
    display: block;

    .icon-play {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 2rem;
        padding: 0.5rem;
    }
}